import { createSetting } from '@shopex/finder'
import { batchDelete} from '../api'
import { useDialog } from '@shopex/finder'
import { MessageBox } from 'element-ui'

export const SELECT_OPTIONS = [
  { label: '需退回', value: '1' },
  { label: '无需退回', value: '2' },
]

export const GOODS_TYPE = [
  { label: '实体赠品', value: 'normal' },
  { label: '虚拟赠品', value: 'services' },
]

export const GOODS_TYPE_OPTIONS = [
  { label: '已发放退货直接抵扣', value: '3' },
]

const commonAction = [
  { label: '取消', key: 'close', size: 'default' },
  { label: '提交', key: 'save', type: 'primary', size: 'default' }
]

export const tableSetting = (vm) => createSetting({
  search: [
    { name: '商品名称', key: 'item_name' },
    { name: '商品货号', key: 'item_bn' },
    {
      name: '商品策略',
      key: 'aftersales_rule',
      type: 'select',
      options: SELECT_OPTIONS
    },
    {
      name: '赠品类型',
      key: 'goods_type',
      type: 'select',
      options: GOODS_TYPE
    },
  ],
  columns: [
    { name: '商品ID', key: 'item_id', width: 80 },
    {
      name: '商品名称',
      key: 'item_name',
      minWidth: 150
    },
    {
      name: '商品规格',
      key: 'item_spec_desc',
      minWidth: 150,
    },
    {
      name: '商品货号',
      key: 'item_bn',
      minWidth: 150,
    },
    {
      name: '商品类型',
      key: 'goods_type',
      minWidth: 150,
      render: (h, { row }) => {
        return row.goods_type == 'normal' ? '实体赠品' : '虚拟赠品'
      }
    },
    {
      name: '售后策略',
      key: 'aftersales_rule_msg',
      minWidth: 150,
    },
    {
      name: '售后金额',
      key: 'aftersales_price',
      minWidth: 150,
      render: (h, { row }) => {
        if(row.aftersales_rule == '2') return '--'
        return '￥' + (row.aftersales_price / 100).toFixed(2)
      }
    },
    {
      name: '商品状态',
      key: 'approve_status',
      minWidth: 150
    }
  ],
  actions: [
    {
      name: '添加',
      slot: 'header',
      type: 'button',
      buttonType: 'primary',
      action: {
        handler: async () => {
          useDialog(null, {
            title: `添加赠品`,
            width: '30%',
            component: () => import('../comps/create.vue'),
            actions: commonAction
          }).then((arg) => {
            if(arg) {
              vm.refresh()
            }
          })
        }
      }
    },
    {
      name: '批量删除',
      slot: 'header',
      type: 'button',
      buttonType: 'primary',
      action: {
        handler: async (rows) => {
          if(!rows.length) {
            vm.$message.error('请选择要删除的赠品')
            return
          }
          MessageBox.confirm('请确认是否批量删除？', '提示').then(async () => {
            const ids = rows.map(v => v.item_id).join(',')
            await batchDelete(ids)
            vm.$message.success('删除成功')
            vm.refresh()
          })
        }
      }
    },
    {
      name: '编辑',
      type: 'button',
      buttonType: 'text',
      action: {
        handler: async (rows) => {
          useDialog(null, {
            title: `编辑赠品`,
            width: '30%',
            orgData: rows[0],
            component: () => import('../comps/edit.vue'),
            actions: commonAction
          }).then((arg) => {
            if(arg) {
              vm.refresh()
            }
          })
        }
      }
    },
    {
      name: '删除',
      type: 'button',
      buttonType: 'text',
      action: {
        handler: async (rows) => {
          MessageBox.confirm('请确认是否删除当前赠品？', '提示').then(async () => {
            const id = rows[0].item_id
            await batchDelete(id)
            vm.$message.success('删除成功')
            vm.refresh()
          })
        }
      }
    },
  ]
})

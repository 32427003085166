<template>
  <div>
    <el-row :gutter="20">
        <span v-if="login_type == 'admin' || login_type == 'staff' || login_type == 'disti'">
      <el-col>
        <el-button type="primary" plain icon="el-icon-circle-plus" @click="appendTop(categoryList)">新增商品分类</el-button>
        <el-button type="primary" @click="updateCategory" :disabled="isDisable">保存分类</el-button>
      </el-col>
        </span>
    </el-row>
    <el-card>
      <el-table
        :data="categoryList"
        row-key="id"
        ref='tableTree'
        default-expand-all
        v-loading="loading"
        :height="wheight-180"
        :tree-props="{children: 'children'}">
        <el-table-column
          label="分类名称"
          min-width="280">
          <template slot-scope="scope">
            <el-input class="input-b" size="mini" v-model="scope.row.category_name" v-if="login_type == 'admin' || login_type == 'staff'  || login_type == 'disti'"></el-input>
            <span v-else class="input-s" size="mini">{{ scope.row.category_name }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="sort"
          label="分类排序"
          sortable
          width="150">
          <template slot-scope="scope">
            <el-input class="input-s" type="number" size="mini" v-model="scope.row.sort" v-if="login_type == 'admin' || login_type == 'staff'  || login_type == 'disti'"></el-input>
            <span v-else class="input-s" size="mini">{{ scope.row.sort }}</span>
          </template>
        </el-table-column>

        <el-table-column prop="is_catpageshow" label="分类页展示">
          <template slot-scope="scope">
            <el-switch  v-if="scope.row.level < 1" v-model="scope.row.is_catpageshow"   @change="handleIsEnableChange(scope.row)"></el-switch>
          </template>
        </el-table-column>

        <el-table-column
          label="分类图片"
          width="150">
          <template slot-scope="scope">
            <span v-if="login_type == 'admin' || login_type == 'staff'  || login_type == 'disti'">
            <el-button type="text" class="el-icon-upload2" @click="handleImgChange(scope.row)">上传</el-button>
            </span>
            <el-image
              v-if="scope.row.image_url"
              style="width: 50px; height: 50px; vertical-align: middle"
              :src="scope.row.image_url"
              :preview-src-list="[scope.row.image_url]"
              fit="cover">
            </el-image>
          </template>
        </el-table-column>
        <el-table-column
          label="分类页ICON"
          width="150">
          <template slot-scope="scope">
            <span v-if="login_type == 'admin' || login_type == 'staff'  || login_type == 'disti'">
            <el-button type="text" class="el-icon-upload2" @click="handleIconChange(scope.row)">icon</el-button>
            </span>
            <el-image
              v-if="scope.row.icon_url"
              style="width: 50px; height: 50px; vertical-align: middle"
              :src="scope.row.icon_url"
              :preview-src-list="[scope.row.icon_url]"
              fit="cover">
            </el-image>
          </template>
        </el-table-column>
        <el-table-column prop="is_enable" style="width: 100px;" label="分类页展示子分类">
          <template slot-scope="scope">
            <el-switch  v-if="scope.row.level < 1" v-model="scope.row.is_enable"   @change="handleIsEnableChange(scope.row)"></el-switch>
          </template>
        </el-table-column>
        <el-table-column
          prop="created"
          label="创建时间"
          width="120">
          <template slot-scope="scope">
            {{ scope.row.created | datetime }}
          </template>
        </el-table-column>
        <el-table-column
          width="180"
          label="操作">
          <template slot-scope="scope">
            <router-link :to="{path: matchInternalRoute('entity/goods/goodsphysical'), query:{category: scope.row.category_id}}">查看商品</router-link>
            <span v-if="login_type == 'admin' || login_type == 'staff'  || login_type == 'disti'">
            <el-button type="text" v-if="scope.row.level < 2" @click="append(scope.row)">新增子类</el-button>
            <el-button type="text" @click.native.prevent="deleteCategory(scope.row)">删除</el-button>
            </span>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <imgPicker :dialog-visible="imgDialog" :sc-status="isGetImage" @chooseImg="pickImg" @closeImgDialog="closeImgDialog"></imgPicker>
    <imgPicker :dialog-visible="iconDialog" :sc-status="isGetIcon" @chooseImg="pickIcon" @closeImgDialog="closeIconDialog"></imgPicker>
  </div>
</template>
<script>
  import { mapGetters } from 'vuex'
  import {getCategory, saveCategory, deleteCategory} from '../../../api/goods'
  import imgPicker from '@/components/imageselect'

  export default {
    components: {
      imgPicker
    },
    data() {
      return {
        login_type: 'default',
        isDisable: false,
        loading: false,
        spaceInput: false,
        categoryList: [],
        total_count: 0,
        params: [],
        imgDialog: false,
        iconDialog: false,

        isGetImage: false,
        isGetIcon: false,
        current: ''
      }
    },
    computed: {
      ...mapGetters([
        'wheight'
      ])
    },
    methods: {
      getCategory() {
        this.loading = true
        getCategory(this.params).then(response => {
          this.categoryList = response.data.data
          this.loading = false
          this.spaceInput = false
        })
      },
      updateCategory() {
        for (var i = 0; i < this.categoryList.length; i++) {
          for (var a = 0; a < this.categoryList.length - 1 - i; a++) {
            if (this.categoryList[a].category_name == this.categoryList[a + 1].category_name) {
              this.$message({
                type: 'error',
                message: '同级分类名称不能相同'
              })
              return false
            }
          }

          if (!this.catNameCheck(this.categoryList[i].category_name)) {
            return false
          }

          for (var j = 0; j < this.categoryList[i].children.length; j++) {
            if (!this.catNameCheck(this.categoryList[i].children[j].category_name)) {
              return false
            }
            for (var b = 0; b < this.categoryList[i].children.length - 1 - j; b++) {
              if (this.categoryList[i].children[b].category_name == this.categoryList[i].children[b + 1].category_name) {
                this.$message({
                  type: 'error',
                  message: '同级分类名称不能相同'
                })
                return false
              }
            }
            // if (!this.catNameCheck(this.categoryList[i].children[j].category_name)) {
            //   return false
            // }
          }
        }

        this.isDisable = true
        setTimeout(() => {
            this.isDisable = false
        }, 1000)

        let form = JSON.stringify(this.categoryList)
        saveCategory({form: form}).then(response => {
          this.$message({
            type: 'success',
            message: '保存分类成功'
          })
          this.getCategory()
        })
      },
      deleteCategory(data) {
        this.$confirm('此操作将删除该分类, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          if (typeof (data.category_id) != 'undefined') {
            deleteCategory(data.category_id).then(response => {
              this.$message({
                type: 'success',
                message: '删除分类成功'
              })
              this.getCategory()
            })
          } else {
            // const parent = node.parent;
            // const children = parent.data.children || parent.data;
            // const index = children.findIndex(d => d.category_name === data.category_name);
            // children.splice(index, 1);
            const { level, id, parent_id } = data
            let key = level === 0 ? id : parent_id
            const categoryList = this.categoryList
            const parentIndex = categoryList.findIndex(item => item.id === key)
            const deleteList = function (children, delId){
              if (!children) return
              for (let i = 0; i < children.length; i++) {
                if (children[i].id === delId) {
                  children.splice(i, 1)
                  return
                } else {
                  deleteList(children[i].children, delId)
                }
              }
            }
            if (key === id) {
              categoryList.splice(parentIndex, 1)
            } else {
              deleteList(categoryList[parentIndex].children, id)
            }
            this.categoryList = categoryList
          }
        }).catch(e => {
          console.log(e)
          this.$message({
            type: 'info',
            message: '已取消'
          })
        })
      },
      append(row) {
        let { children: data, level = 0, id, parent_id = '' } = row
        let newParentId = level === 0 ? id : parent_id
        const newChild = {
          id: Date.parse(new Date())/1000,
          category_name: '',
          sort: 0,
          level: ++level,
          children: [],
          created: Date.parse(new Date())/1000,
          image_url: '',
          icon_url: '',
          is_enable: 0,
          is_catpageshow: 0,
          parent_id: newParentId
        }
        data.push(newChild)
      },
      appendTop(data) {
        const newChild = {id: Date.parse(new Date())/1000, category_name: '', sort: 0, level: 0, children: [], created: Date.parse(new Date())/1000, image_url: '',icon_url: '',is_enable: 0,is_catpageshow: 0}
        data.push(newChild)
        this.$nextTick(() => {
          let container = this.$el.querySelector('.el-table__body-wrapper');
          container.scrollTop = container.scrollHeight;
        })
      },
      catNameCheck(catName) {
        let catNameLength = 0
        if (catName) {
          for (var i = 0; i < catName.length; i++) {
            if (catName.charCodeAt(i) > 255) {
              catNameLength += 2;
            } else {
              catNameLength++;
            }
          }
          if (catNameLength > 50) {
            this.$message({
              type: 'error',
              message: '分类名称长度最多25个汉字或50个字符'
            })
            return false
          }
          return true
        } else {
          this.$message({
            type: 'error',
            message: '分类不能为空'
          })
          return false
        }
      },
      handleImgChange(data) {
        this.imgDialog = true
        this.isGetImage = true
        this.current = data
      },

      handleIconChange(data) {
        this.iconDialog = true
        this.isGetIcon = true
        this.current = data
      },
      handleIsEnableChange(data) {

      },
      pickImg(data) {
        if (!this.current.parent_id || this.current.parent_id == 0) {
          const index = this.categoryList.findIndex(d => d.id === this.current.id);
          this.categoryList[index].image_url = data.url
        } else if (this.current.parent_id && this.current.level == 1) {
          const findex = this.categoryList.findIndex(d => d.id === this.current.parent_id);
          const cindex = this.categoryList[findex].children.findIndex(d => d.id === this.current.id);
          this.categoryList[findex].children[cindex].image_url = data.url
        } else {
          let findex
          let cindex
          for (var item in this.categoryList) {
            cindex = this.categoryList[item].children.findIndex(d => d.id === this.current.parent_id);
            findex = item
            if (cindex > -1) break
          }
          const tindex = this.categoryList[findex].children[cindex].children.findIndex(d => d.id === this.current.id);
          this.categoryList[findex].children[cindex].children[tindex].image_url = data.url

        }
        this.imgDialog = false
      },
      pickIcon(data) {
        if (!this.current.parent_id || this.current.parent_id == 0) {
          const index = this.categoryList.findIndex(d => d.id === this.current.id);
          this.categoryList[index].icon_url = data.url
        } else if (this.current.parent_id && this.current.level == 1) {
          const findex = this.categoryList.findIndex(d => d.id === this.current.parent_id);
          const cindex = this.categoryList[findex].children.findIndex(d => d.id === this.current.id);
          this.categoryList[findex].children[cindex].icon_url = data.url
        } else {
          let findex
          let cindex
          for (var item in this.categoryList) {
            cindex = this.categoryList[item].children.findIndex(d => d.id === this.current.parent_id);
            findex = item
            if (cindex > -1) break
          }
          const tindex = this.categoryList[findex].children[cindex].children.findIndex(d => d.id === this.current.id);
          this.categoryList[findex].children[cindex].children[tindex].icon_url = data.url

        }
        this.iconDialog = false
      },
      closeImgDialog() {
        this.imgDialog = false
      },
      closeIconDialog() {
        this.iconDialog = false
      },
    },

    mounted() {
      this.getCategory()
      this.login_type = this.$store.getters.login_type
    }
  };
</script>

<template>
  <div>
    <div v-if="$route.path.indexOf('_detail') === -1">
      <el-row class="filter-header" :gutter="20">
        <el-col>
          <el-date-picker
            v-model="params.create_time"
            class="input-m"
            type="daterange"
            value-format="yyyy/MM/dd"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="search"
          ></el-date-picker>
          <el-input class="input-m" placeholder="售后单号" v-model="params.aftersales_bn" @input="search" clearable>
          </el-input>
          <el-input class="input-m" placeholder="订单号" v-model="params.order_id" @input="search" clearable>
          </el-input>
          <el-select
            v-model="params.aftersales_type"
            clearable
            @change="search"
            placeholder="请选择售后类型"
          >
            <el-option
              v-for="(item, index) in aftersalesTypeList"
              :key="index"
              :label="item.name"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-select v-model="params.is_ec" clearable @change="search" placeholder="是否为员工购">
            <el-option
              v-for="(item, index) in is_ecs"
              :key="index"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-select v-model="params.exist_subafter" clearable @change="search" placeholder="是否包含赠品">
            <el-option
              v-for="(item, index) in exist_subafter"
              :key="index"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-button class="btn_input" @click="handleClick('shop')">
            {{ shopText }}
            <i :class="getIconClass(relDistributors)" @click.stop="handleClear('shop')"></i>
          </el-button>
          <el-button class="btn_input" @click="handleClick('dealer')">
            {{ dealerText }}
            <i :class="getIconClass(relDealers)" @click.stop="handleClear('dealer')"></i>
          </el-button>
          <el-button type="primary" @click="exportData" size="medium">导出</el-button>
          <el-popover
            placement="top-start"
            width="200"
            trigger="hover"
            content="导出任务会以队列执行，点击导出后，请至‘设置-导出列表’页面中查看及下载数据"
          >
            <i class="el-icon-question" slot="reference"></i>
          </el-popover>
          <!--          <el-button type="primary" @click="aftersalesRemindAction">售后提醒内容</el-button>-->
        </el-col>
      </el-row>
      <el-card>
        <el-tabs v-model="aftersales_status" @tab-click="aftersalesStatusSelectHandle">
          <el-tab-pane v-for="(item, index) in aftersalesStatusList" :label="item.name" :name="item.value" :key="index"></el-tab-pane>
        </el-tabs>
        <el-table :data="list" v-loading="loading" element-loading-text="数据加载中" :row-class-name="iconHide">
          <el-table-column type="expand" width="30">
            <template slot-scope="scope">
              <SubList :list="scope.row?.gift_after || []"/>
            </template>
          </el-table-column>
          <el-table-column
            prop="gift_after"
            width="250"
            label="申请商品"
            align="center"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <div v-for="detail of scope.row?.detail" :key="detail.detail_id">
                <div class="order-box">
                  <div class="order-image">
                    <img :src="detail?.item_pic" alt="">
                  </div>
                  <div>
                    <div class="order-name">
                      {{ detail?.item_name }}
                    </div>
                    <div class="order-price">
                      <span>实付：</span>
                      <span>￥{{ detail?.refund_fee / 100 }} </span>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="aftersales_bn"
            width="200"
            label="售后单号"
            align="center"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <div class="order-num">
                {{ scope.row.aftersales_bn }}
                <el-tooltip effect="dark" content="复制" placement="top-start">
                  <i
                    v-clipboard:copy="scope.row.aftersales_bn"
                    v-clipboard:success="onCopy"
                    class="el-icon-document-copy"
                  ></i>
                </el-tooltip>
              </div>
              <div class="order-time">
                <el-tooltip effect="dark" content="申请时间" placement="top-start">
                  <i class="el-icon-time"></i>
                </el-tooltip>
                {{ scope.row.create_time | datetime('yyyy-MM-dd hh:mm:ss') }}
              </div>
            </template>
          </el-table-column>
          <el-table-column width="200" label="订单" align="center" show-overflow-tooltip>
            <template slot-scope="scope">
              <div class="order-num">
                <router-link
                  :to="{
                    path: prefix + '/order/entitytrade/tradenormalorders/tradenormalorders_detail',
                    query: { orderId: scope.row.order_id }
                  }"
                  >{{ scope.row.order_id }}</router-link
                >
                <el-tooltip effect="dark" content="复制" placement="top-start">
                  <i
                    v-clipboard:copy="scope.row.order_id"
                    v-clipboard:success="onCopy"
                    class="el-icon-document-copy"
                  ></i>
                </el-tooltip>
              </div>
              <div class="order-time" v-if="scope.row.ecid !== '' && scope.row.ecid != undefined">
                <el-tag size="mini" type="warning">员工购</el-tag>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            width="150"
            label="所属店铺"
            property="shop_info"
            align="center"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <div class="org-info">
                <div>店铺AppleID：{{ scope.row.shop_code }}</div>
                <div>店铺名称：{{ scope.row.distributor_name }}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            width="150"
            label="组织信息"
            property="info"
            align="center"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <div class="org-info">
                <div>经销商名称：{{ scope.row.dealer_name }}</div>
                <div>经销商HQID：{{ scope.row.dealer_hqid }}</div>
                <div>分销商HQID：{{ scope.row.distributor_hqid }}</div>
                <div>分销商VAD：{{ scope.row.distributor_vad }}</div>
                <div>渠道RTM：{{ scope.row.channel_rtm }}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            width="150"
            label="收货人手机号"
            property="receiver_mobile"
            align="center"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column width="120" label="售后类型" align="center" show-overflow-tooltip>
            <template slot-scope="scope">
              <el-tag v-if="scope.row.aftersales_type === 'ONLY_REFUND'" type="info" size="mini"
                >仅退款</el-tag
              >
              <el-tag
                v-if="scope.row.aftersales_type === 'SEVEN_REFUND_GOODS'"
                type="info"
                size="mini"
                >7天无理由</el-tag
              >
              <el-tag v-if="scope.row.aftersales_type === 'REFUND_GOODS'" type="warning" size="mini"
                >7天质量问题</el-tag
              >
              <el-tag
                v-if="scope.row.aftersales_type === 'EXCHANGING_GOODS'"
                type="danger"
                size="mini"
                >15天内换货</el-tag
              >
            </template>
          </el-table-column>
          <el-table-column width="120" label="售后状态" align="center" show-overflow-tooltip>
            <template slot-scope="scope">
              <el-tag v-if="scope.row.aftersales_status == '0'" size="mini">待处理</el-tag>
              <el-tag v-if="scope.row.aftersales_status == '1'" size="mini">处理中</el-tag>
              <el-tag v-if="scope.row.aftersales_status == '2'" type="success" size="mini"
                >已处理</el-tag
              >
              <el-tag v-if="scope.row.aftersales_status == '3'" type="success" size="mini"
                >已驳回</el-tag
              >
              <el-tag v-if="scope.row.aftersales_status == '4'" type="success" size="mini"
                >已关闭</el-tag
              >
              <el-tag v-if="scope.row.aftersales_status == '5'" type="success" size="mini"
                >已收货</el-tag
              >
              <el-tag v-if="scope.row.aftersales_status == '6'" type="success" size="mini"
                >强制关闭</el-tag
              >
            </template>
          </el-table-column>
          <el-table-column minWidth="150" label="操作" align="center" fixed="right">
            <template slot-scope="scope">
              <el-link :underline="false">
                <router-link
                  :to="{
                    path: matchHidePage('detail'),
                    query: { aftersales_bn: scope.row.aftersales_bn }
                  }"
                  >详情</router-link
                >
              </el-link>
              <el-button
                v-if="login_type !== 'disti' && showComfireReceipt(scope.row)"
                type="primary"
                size="mini"
                @click="handleConfirmReceipt(scope.row)"
                >收货</el-button>
              <el-button
                v-if="
                  scope.row.aftersales_status != '6' &&
                  scope.row.progress == '8' &&
                  scope.row.aftersales_type != 'EXCHANGING_GOODS' &&
                  login_type !== 'disti'
                "
                type="danger"
                size="mini"
                @click="handleRefund(scope.row)"
                >退款</el-button>
              <el-link
                v-if="
                  scope.row.aftersales_status != '6' &&
                  scope.row.progress == '8' &&
                  scope.row.aftersales_type == 'EXCHANGING_GOODS' &&
                  login_type !== 'disti'
                "
                type="primary"
                @click="handleExchangingGoods(scope.row)"
                :underline="false"
                >换货</el-link
              >
<!--              <el-link-->
<!--                v-if="-->
<!--                  (scope.row.aftersales_status < '2' || scope.row.aftersales_status == '5') &&-->
<!--                  login_type !== 'disti'-->
<!--                "-->
<!--                type="primary"-->
<!--                @click="ForcedClosure(scope.row)"-->
<!--                :underline="false"-->
<!--                >强制关闭售后</el-link-->
<!--              >-->
            </template>
          </el-table-column>
        </el-table>
        <div class="content-padded content-center">
          <el-pagination
            background
            layout="total, sizes, prev, pager, next, jumper"
            @current-change="handleCurrentChange"
            @size-change="handleSizeChange"
            :current-page.sync="params.page"
            :page-sizes="[10, 20, 50]"
            :total="total_count"
            :page-size="params.pageSize"
          >
          </el-pagination>
        </div>
      </el-card>
      <!-- 售后提醒内容 -开始 -->
      <el-dialog
        :title="aftersalesRemindTitle"
        :visible.sync="aftersalesRemindVisible"
        :before-close="handleCancel"
        width="65%"
      >
        <template>
          <el-form
            ref="aftersalesRemindForm"
            :model="aftersalesRemindForm"
            class="demo-ruleForm"
            label-width="100px"
          >
            <el-form-item label="提醒内容">
              <template>
                <vue-html5-editor
                  :content="aftersalesRemindForm.intro.toString()"
                  ref="editor"
                  @change="updateContent"
                  :height="360"
                ></vue-html5-editor>
              </template>
              <span class="frm-tips"
                >例如：由于商品的特殊性，如涉及机油类产品需寄回，建议使用京东快递，快递公司联系电话955XX。</span
              >
            </el-form-item>
            <el-form-item label="是否启用">
              <el-switch
                v-model="aftersalesRemindForm.is_open"
                active-color="#13ce66"
                inactive-color="#ff4949"
              ></el-switch>
              <br />
              <span class="frm-tips"
                >如开启展示，则后台所输入内容将会展示在前端消费者提交售后申请的页面上，内容不超过200字</span
              >
            </el-form-item>
          </el-form>
        </template>
        <div slot="footer" class="dialog-footer">
          <el-button @click.native="handleCancel">取消</el-button>
          <el-button type="primary" @click="submitAftersalesRemind">确定</el-button>
        </div>
      </el-dialog>
      <el-dialog
        title="关闭售后"
        :visible.sync="forcedClosureVisible"
        :before-close="handleForcedClosure"
        width="57%"
      >
        <template>
          <el-form ref="cancelForm" :model="forcedClosure" class="" label-width="100px">
            <el-form-item label="订单号:">
              <el-col :span="20">{{ forcedClosure.order_id }}</el-col>
            </el-form-item>
            <el-form-item label="关闭原因:">
              <el-row>
                <el-col :span="20">
                  <template>
                    <el-select
                      v-model="reason"
                      filterable
                      placeholder="请选择关闭售后原因"
                      @change="reasonSelect"
                    >
                      <el-option v-for="item in reasonList" :key="item" :label="item" :value="item">
                      </el-option>
                    </el-select>
                    <el-input
                      v-show="input"
                      v-model="forcedClosure.other_reason"
                      type="textarea"
                      :rows="3"
                      resize="none"
                      style="width: 200px; margin-top: 10px"
                      placeholder="请填写关闭原因"
                    ></el-input>
                  </template>
                </el-col>
              </el-row>
            </el-form-item>
          </el-form>
        </template>
        <div slot="footer" class="dialog-footer">
          <el-button @click.native="handleForcedClosure">取消</el-button>
          <el-button type="primary" @click="doForcedClose">确定</el-button>
        </div>
      </el-dialog>
      <DistributorSelect
        :store-visible="shopVisible"
        :rel-data-ids="relDistributors"
        @chooseStore="(data) => chooseAction('shop', data)"
        @closeStoreDialog="handleClose('shop')"
      />
      <DealerSelect
        :store-visible="dealerVisible"
        :rel-data-ids="relDealers"
        @chooseStore="(data) => chooseAction('dealer', data)"
        @closeStoreDialog="handleClose('dealer')"
      />
    </div>
    <router-view></router-view>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { getDistributorList } from '../../../api/marketing'
import {
  getAftersalesList,
  exportList,
  reviewAftersales,
  getAftersalesRemind,
  setAftersalesRemind,
  refundCheck,
  sendConfirm,
  updateAfterSalesStatus
} from '../../../api/aftersales'
import { getAftersalesReason } from '../../../api/aftersalesreason'
import DistributorSelect from '@/components/function/distributorSelect'
import DealerSelect from '@/components/function/dealerSelect'
import SubList from './subList'

export default {
  components: {
    DistributorSelect,
    DealerSelect,
    SubList
  },
  data() {
    return {
      prefix: '',
      input: false,
      reason: '',
      reasonList: [],
      forcedCloseReason: [],
      forcedClosureVisible: false,
      forcedClosure: {
        order_id: '',
        aftersales_bn: '',
        aftersales_status: '',
        refuse_reason: ''
      },
      loading: false,
      params: {
        page: 1,
        pageSize: 20,
        create_time: '',
        time_start_begin: '',
        time_start_end: '',
        shop_id: '',
        aftersales_bn: '',
        order_id: '',
        aftersales_type: '',
        is_ec: '',
        exist_subafter: ''
      },
      aftersalesStatusList: [
        { name: '全部', value: 'all' },
        { name: '待处理', value: '0' },
        { name: '处理中', value: '1' },
        { name: '已收货', value: '5' },
        { name: '已处理', value: '2' },
        { name: '已驳回', value: '3' }
      ],
      is_ecs: [
        {
          value: 'true',
          label: '员工购'
        },
        {
          value: 'false',
          label: '非员工购'
        }
      ],
      exist_subafter: [
        {
          value: '1',
          label: '包含赠品'
        },
        {
          value: '0',
          label: '不包含赠品'
        }
      ],
      aftersalesTypeList: [
        { name: '仅退款', value: 'ONLY_REFUND' },
        { name: '7天无理由', value: 'SEVEN_REFUND_GOODS' },
        { name: '7天质量问题', value: 'REFUND_GOODS' },
        { name: '15天内换货', value: 'EXCHANGING_GOODS' }
      ],
      aftersales_status: 'all',
      total_count: 0,
      list: [],
      mobile: '',
      receiver_mobile: '',
      wxShopsList: [],
      aftersalesRemindForm: {
        intro: '',
        is_open: false
      },
      aftersalesRemindVisible: false,
      aftersalesRemindTitle: '售后提醒内容',
      shopVisible: false,
      dealerVisible: false,
      relDistributors: [],
      relDealers: [],
      distributor_id: '',
      dealer_id: '',
      selectConfig: {
        shop: {
          visible: 'shopVisible',
          defaultText: '归属门店',
          relData: 'relDistributors',
          idKey: 'distributor_id'
        },
        dealer: {
          visible: 'dealerVisible',
          defaultText: '归属经销商',
          relData: 'relDealers',
          idKey: 'dealer_id'
        }
      },
    }
  },
  computed: {
    ...mapGetters(['wheight', 'login_type']),
    shopText() {
      return this.getSelectedText(this.relDistributors, 'shop')
    },
    dealerText() {
      return this.getSelectedText(this.relDealers, 'dealer')
    }
  },
  mounted() {
    switch (this.login_type) {
      case 'disti': // 代理端
        this.prefix = '/distiadmin'
        break
      case 'dealer': // 经销商端
        this.prefix = '/dealeradmin'
        break
      case 'distributor': // 店铺端
        this.prefix = '/shopadmin'
        break
      default: // 平台  包括admin  staff
        this.prefix = ''
    }
    this.getStoreList()
    this.getAftersalesList(this.params)
    this.getAftersalesReasonList()
  },
  methods: {
    showComfireReceipt(row) {
      if (
        row.aftersales_status != '6' &&
        row.progress == '2' &&
        row.aftersales_type != 'ONLY_REFUND'
      ) {
        // 7天无理由退货 邮寄 时，没有 物流单号就不显示 确认收货按钮  2021年08月30日19:02:51
        if (
          row.aftersales_type == 'SEVEN_REFUND_GOODS' &&
          row.sendback_data.is_logistics == 'true' &&
          row.sendback_data.delivery_code == ''
        ) {
          // return false; // 谢金萍讲，有可能 用户不邮寄了，可能出差顺便送回去了，就不要 不显示 确认收货 按钮，言外之意就是 只要  审核了，就一直显示确认收货按钮 2021年08月30日19:11:58
        }

        return true
      }
      // scope.row.aftersales_status != '6' && scope.row.progress == '2' && scope.row.aftersales_type != 'ONLY_REFUND'
    },

    handleForcedClosure() {
      this.forcedClosureVisible = false
      this.forcedClosure.order_id = ''
      this.forcedClosure.aftersales_bn = ''
      this.forcedClosure.refuse_reason = ''
      this.reason = ''
    },
    getAftersalesReasonList() {
      getAftersalesReason([]).then((res) => {
        this.reasonList = res.data.data
      })
    },
    // 原因选择
    reasonSelect(val) {
      console.log(val, '===============')
      if (val == 12) {
        this.input = true
      } else {
        this.input = false
      }
      this.forcedClosure.refuse_reason = val
    },
    // 强制关闭 售后
    ForcedClosure(row) {
      this.forcedClosureVisible = true
      this.forcedClosure.order_id = row.order_id
      this.forcedClosure.aftersales_bn = row.aftersales_bn
      console.log(row, '=====')
    },
    doForcedClose() {
      let data = {
        ...this.forcedClosure,
        aftersales_status: 6
      }
      updateAfterSalesStatus(data).then((res) => {
        if (res.data.data) {
          this.$message({
            message: '操作强制关闭完成',
            type: 'success',
            duration: 3 * 1000
          })
          this.handleForcedClosure()
          this.getAftersalesList(this.params)
        } else {
          this.$message.error('操作失败！')
          return false
        }
      })
    },
    comfirmText(gift_after) {
      return `
        <div>
          ${gift_after?.length ? `商品包含需退回的赠品，请确认
          <span style="color: #000000;font-weight: bold;">货品</span> 与
          <span style="color: #000000;font-weight: bold;">赠品</span>
          都已收到。` : '请确认已收到消费者的退货'}
        </div>
        <div style="background-color: #fdf6ec;color: #e6a23c;font-size: 13px;padding: 4px;margin-top: 8px;">
          <i class="el-icon-warning" style="margin: 0 6px;"></i>
          操作后不可撤回，请谨慎处理
        </div>
      `
    },

    // 操作 确认收货
    handleConfirmReceipt(row) {
      this.$confirm(this.comfirmText(row.gift_after), '收货确认', {
        distinguishCancelAndClose: true,
        confirmButtonText: '确认收货',
        cancelButtonText: '拒绝收货',
        customClass: 'aftersales-confirm',
        dangerouslyUseHTMLString: true,
      })
        .then(() => {
          this.doConfirmReceipt(row)
        })
        .catch((action) => {
          if (action === 'cancel') {
            this.doConfirmReceipt(row, {aftersales_status: 3, progress: 10})
          } else {
            console.log('关闭')
          }
        })
    },

    // 操作 同意退款 - 拒绝退款
    handleRefund(row) {
      let showCancelButton = true
      // 7天质量  隐藏 拒绝退款按钮 和 7 天无理由的均隐藏
      if (row.aftersales_type === 'SEVEN_REFUND_GOODS') {
        // showCancelButton = false;
      }
      this.$confirm(this.comfirmText(row.gift_after), '退款确认', {
        distinguishCancelAndClose: true,
        confirmButtonText: '同意退款',
        cancelButtonText: '拒绝退款',
        showCancelButton,
        customClass: 'aftersales-confirm',
        dangerouslyUseHTMLString: true,
      })
        .then(() => {
          this.doRefund(row, 1)
        })
        .catch((action) => {
          if (action === 'cancel') {
            console.log('拒绝')
            this.doRefund(row, 0)
          } else {
            console.log('关闭')
          }
        })
    },

    // 操作 换货 - 同意 / 拒绝
    handleExchangingGoods(row) {
      // 是否换货
      this.$confirm('请确认是否换货？', '换货提示', {
        distinguishCancelAndClose: true,
        confirmButtonText: '同意换货',
        cancelButtonText: '拒绝换货'
      })
        .then(() => {
          this.doSendConfirm(row, 1)
        })
        .catch((action) => {
          if (action == 'cancel') {
            this.doSendConfirm(row, 0)
          } else {
            console.log('关闭')
          }
        })
    },

    // 确认收货
    doConfirmReceipt(row, otherParams) {
      let msg = '操作成功，确认收货！', type = 'success'
      if (otherParams) {
        msg = '操作成功，拒绝收货！'
        type = 'info'
      }
      // 确认收货
      updateAfterSalesStatus({
        order_id: row.order_id,
        aftersales_bn: row.aftersales_bn,
        aftersales_status: 5,
        progress: 8,
        ...otherParams
      }).then((response) => {
        if (response.data.data) {
          this.$message({
            message: msg,
            type,
            duration: 3 * 1000
          })
          this.getAftersalesList(this.params)
        } else {
          this.$message.error('操作失败！')
          return false
        }
      })
    },

    doSendConfirm(row, type) {
      let msg = '操作成功，拒绝退货！',
        typeMsg = 'info'
      if (type) {
        msg = '操作成功，同意退货！'
        typeMsg = 'success'
      }
      sendConfirm({
        type,
        aftersales_bn: row.aftersales_bn,
        corp_code: '0000000',
        logi_no: '管理员操作换货' + msg
      }).then((response) => {
        if (response.data.data) {
          this.$message({
            message: msg,
            type: typeMsg,
            duration: 3 * 1000
          })
          this.getAftersalesList(this.params)
        } else {
          this.$message.error('操作失败！')
          return false
        }
      })
    },

    doRefund(row, check_refund) {
      let msg = '操作成功，拒绝退款！',
        type = 'info'
      if (check_refund) {
        msg = '操作成功，同意退款！'
        type = 'success'
      }
      refundCheck({
        aftersales_bn: row.aftersales_bn,
        check_refund: check_refund,
        refund_fee: row.refund_fee,
        refund_point: row.refund_point,
        refunds_memo: '管理员操作退款' + msg
      }).then((response) => {
        if (response.data.data) {
          this.$message({
            message: msg,
            type: type,
            duration: 3 * 1000
          })
          this.getAftersalesList(this.params)
        } else {
          this.$message.error('操作失败！')
          return false
        }
      })
    },
    onCopy() {
      this.$notify.success({
        message: '复制成功',
        showClose: true
      })
    },
    aftersalesStatusSelectHandle() {
      if(this.aftersales_status == 'all') {
        this.params.aftersales_status = ''
      } else {
        this.params.aftersales_status = this.aftersales_status
      }
      this.params.page = 1
      this.getAftersalesList(this.params)
    },
    search(e) {
      const create_time = this.params.create_time
      if (create_time?.length > 0) {
        this.params.time_start_begin = this.dateStrToTimeStamp(create_time[0] + ' 00:00:00')
        this.params.time_start_end = this.dateStrToTimeStamp(create_time[1] + ' 23:59:59')
      } else {
        this.params.time_start_begin = ''
        this.params.time_start_end = ''
      }
      this.params.distributor_id = this.distributor_id
      this.params.dealer_id = this.dealer_id
      this.params.page = 1
      this.getAftersalesList(this.params)
    },
    mobileSearch() {
      this.params.page = 1
      this.getAftersalesList(this.params)
    },
    sourceSearch(item) {
      this.params.source_id = item.source_id
      this.params.page = 1
      this.getAftersalesList(this.params)
    },
    handleCurrentChange(val) {
      this.params.page = val
      this.loading = false
      this.getAftersalesList(this.params)
    },
    handleSizeChange(pageSize) {
      this.loading = false
      this.params.page = 1
      this.params.pageSize = pageSize
      this.getAftersalesList(this.params)
    },
    dateStrToTimeStamp(str) {
      return Date.parse(new Date(str)) / 1000
    },
    getAftersalesList(filter) {
      this.loading = true
      getAftersalesList(filter).then((response) => {
        this.list = response.data.data.list
        this.total_count = Number(response.data.data.total_count)
        this.loading = false
      })
    },
    querySearch(queryString, cb) {
      var restaurants = this.source_list
      var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants
      // 调用 callback 返回建议列表的数据
      cb(results)
    },
    createFilter(queryString) {
      return (restaurant) => {
        return restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0
      }
    },
    getStoreList() {
      this.loading = true
      let params = { page: 1, pageSize: 500 }
      getDistributorList(params).then((response) => {
        if (response.data.data.list) {
          response.data.data.list.forEach((row) => {
            this.wxShopsList.push({ 'value': row.name, 'distributor_id': row.distributor_id })
          })
        }
        this.loading = false
      })
    },
    exportData() {
      this.params.page = 1
      exportList(this.params).then((response) => {
        if (response.data.data.status) {
          this.$message({
            type: 'success',
            message: '已加入执行队列，请在设置-导出列表中下载'
          })
          return
        } else if (response.data.data.url) {
          this.downloadUrl = response.data.data.url
          this.downloadName = response.data.data.filename
          this.downloadView = true
        } else {
          this.$message({
            type: 'error',
            message: '没有相关数据可导出'
          })
        }
      })
    },
    aftersalesRemindAction() {
      // 请求提醒数据
      getAftersalesRemind().then((response) => {
        if (response.data.data) {
          this.aftersalesRemindForm = response.data.data
        }
      })
      this.aftersalesRemindVisible = true
    },
    updateContent: function (data) {
      this.aftersalesRemindForm.intro = data
    },
    handleCancel() {
      this.aftersalesRemindVisible = false
      this.aftersalesRemindForm.intro = ''
      this.aftersalesRemindForm.is_open = false
    },
    submitAftersalesRemind() {
      let params = {
        intro: this.aftersalesRemindForm.intro,
        is_open: this.aftersalesRemindForm.is_open
      }
      setAftersalesRemind(params).then((response) => {
        this.$message({
          type: 'success',
          message: '保存成功'
        }),
          (this.aftersalesRemindVisible = false)
      })
    },
    getIconClass(items) {
      return items.length === 0 ? 'el-icon-circle-plus icon' : 'el-icon-circle-close icon'
    },
    getSelectedText(items, type) {
      return items.length > 0 ? `已选择${items.length}` : this.selectConfig[type].defaultText
    },
    handleClick(type) {
      this[this.selectConfig[type].visible] = true
    },
    handleClear(type) {
      const config = this.selectConfig[type]
      this[config.relData] = []
      this[config.idKey] = ''
      this.search()
    },
    handleClose(type) {
      this[this.selectConfig[type].visible] = false
    },
    chooseAction(type, data) {
      const config = this.selectConfig[type]
      this[config.relData] = data
      const ids = data.map(item => item[config.idKey]).join(',')
      this[config.idKey] = ids
      this.handleClose(type)
      this.search()
    },
    iconHide({ row }) {
      if (!row?.gift_after?.length) {
        return 'icon-no'
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.order-num {
  font-size: 14px;
}
.order-box {
  display: flex;
  align-items: center;
  font-size: 12px;
  .order-image {
    margin:0 8px;
    img {
      width: 80px;
      height: 80px;
    }
  }
  .order-price {
    display: flex;
  }
}
.org-info {
  font-size: 12px;
  color: #999;
}

::v-deep .el-table__expanded-cell{
  padding: 0 !important;
  .el-table__cell {
    padding: 6px 0;
  }
}
::v-deep .icon-no .el-table__expand-icon {
  display: none;
}
</style>

<style lang="scss">
.aftersales-confirm {
  .el-message-box__btns > .el-button:first-child  {
    background-color: #F56C6C !important;
    color: #FFF;
  }
}
</style>
